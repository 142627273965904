/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat");

/* Style Sheets Import */
@import url("./style/globalStyle.css");
@import url("./style/antdComponentOverWrite.css");

:root {
  --primary_text_color: rgba(0, 0, 0, 0.8);
  --primary_error_text_color: rgba(230, 22, 22, 0.925);

  --active_submenu_color: #396caa83;
  --active_submenu_text_color: white;

  --active_menu_item_color: #8245d388;
  --active_menu_item_text_color: white;

  --hover_menu_item_color: #f59432;

  --default_font_size: 14px;
  --default_font_size_small: 12px;

  --default_border: 1px solid rgba(0, 0, 0, 0.1);
}

body {
  color: var(--primary_text_color);
  font-size: var(--default_font_size);
  font-family: Montserrat;
  background-color: #f8f8f8 !important;
}

.hover_menu-item:hover {
  color: var(--hover_menu_item_color) !important;
}

.submenu_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.submenu_container:hover .dropdown-icon {
  background-color: var(--hover_menu_item_color) !important;
}
