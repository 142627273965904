/*
* Author: Hasib
*/

:root {
  --headline_color: white;
  --error: rgba(218, 34, 21, 0.89);
}

.pa-4 {
  padding: 16px;
}
.pa-2 {
  padding: 8px;
}

.pl-4 {
  padding-left: 16px;
}

.mt-4 {
  margin-top: 16px !important;
}
.mt-8 {
  margin-top: 32px !important;
}
.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-4 {
  margin-left: 16px;
}
.mr-8 {
  margin-right: 32px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-2 {
  margin-right: 8px;
}

.card {
  background-color: white;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.02);
}

.border_danger {
  border: 1px solid red !important;
}

.success_btn {
  background-color: rgba(21, 218, 21, 0.89);
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 0 30px;
  border: none;
}
.error_btn {
  background-color: rgba(218, 34, 21, 0.89);
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 0 30px;
  border: none;
}

.required::after {
  content: "*";
  color: var(--error);
  margin-left: 5px;
  font-weight: 500;
}

.text-end {
  text-align: end;
}

.divider {
  border-top: var(--default_border);
  margin: 30px 0;
}

.display_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align_item_end {
  display: flex;
  align-items: flex-end;
}
.align_item_center {
  display: flex;
  align-items: center;
}

.justify_content_end {
  justify-content: flex-end !important;
}

.bolder {
  font-weight: bolder;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}

.error_text {
  color: var(--primary_error_text_color) !important;
  font-size: var(--default_font_size_small) !important;
  font-weight: 700;
}

.small_text {
  font-size: var(--default_font_size_small);
  font-weight: 500;
}

.border_none {
  border: none;
}

.label {
  color: var(--primary_text_color);
  font-size: var(--default_font_size);
  font-weight: 500;
}

.header {
  background: #ffffff;
  height: 100px;
  padding: 20px;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.sidebar {
  background-color: #ffffff;
  box-shadow: 0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);
  height: 100%;
  height: calc(100vh - 100px);
  width: 300px;
  padding-top: 20px;
  position: sticky;
  top: 100px !important;
}

.content {
  width: calc(100vw - 300px);
  padding: 20px;
}
