.loaderContainer{
    position:absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(254, 255, 225, 0.534);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loader{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 5px solid #e9218c;
    border-radius: 50%;
    border-left-color: transparent;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
}

.loader::after{
    content: '';
    position: absolute;
    width: 160%;
    height: 161%;
    border: 5px solid #2490fd;
    border-radius: 50%;
    border-right-color: transparent;
    left: -13px;
    top: -12px;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
}


.loader::before{
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    border: 5px solid #fcb100;
    border-radius: 50%;
    border-left-color: transparent;
    left: -21px;
    top: -20px;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
}

.loader:hover, .loader::after, .loader::before{
    animation-play-state: paused;
}


@keyframes spin {
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(360deg);
    }
}