/*  Style for Options of Select Component */
.initial-whitespace-child > * {
  white-space: initial !important;
}

/* Antd Checkbox */
.antd-checkbox-large .ant-checkbox-inner {
  height: 20px;
  width: 20px;
}
